@import "../../App.scss";
@import "../../variables.scss";

.partnersText {
  margin-top: 50px;
  padding: 50px;
  color: #EEEEEE;
  background: #111111;
  border-radius: 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  // @media (orientation: portrait) {
  //   justify-content: center;
  // }

  @media (orientation: landscape) {
    justify-content: flex-start;
  }

  & .roleDescription {
    text-align: center;
  }
}

.partnerName {
  font-weight: bold;
}

.verbatim {
  font-size: 14px;
  display: block;
}


.withVerbatim {
  flex-direction: column !important;
}

.nameCard {

  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  & img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  .position {
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #171717;
    padding: 1px 5px;
  }

  // @media (orientation: landscape) {
  //   width: 40%;
  //   // margin: 50px;
  // }

  @media (orientation: portrait) {
    // width: 100%;
    // margin: 10px;
  }
}