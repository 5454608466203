@import "../../App.scss";
@import "../../variables.scss";

.bookWrapper {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  h1 {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  h2 {
    text-decoration: underline;
    color: white;
    padding: 10px;
  }
}

.bookText {
  color: white;
}

.subSection {
  margin-bottom: 50px;
}
