.pageWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  z-index: 1;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 100px 20px 100px 20px;
  transition: all 1s;

  @media (orientation: portrait) {
    padding: 100px 20px 100px 20px;
    width: calc(100% - 40px);
  }
}