body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #171717;
  background: #171717;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "DccDreamer";
  src: local("DccDreamer"),
    url("./fonts/DccDreamer/dcc-dreamer.otf") format("opentype");
}

@font-face {
  font-family: "Osaka";
  src: local("Osaka"), url("./fonts/Osaka/osaka-re.ttf") format("truetype");
}

@font-face {
  font-family: "Bungee";
  src: local("Bungee"), url("./fonts/Bungee/Bungee-Regular.ttf") format("truetype");
}
