@import "../../App.scss";

.topbar {
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 0;
  // animation: logoAppears 7s 0s ease-in-out;
  // justify-content: space-around;
  // @media (orientation: portrait) {
  justify-content: space-between;
  // }
  align-items: center;
  left: 0px;
  position: fixed;
  top: 0px;
  padding: 10px;
  width: calc(100% - 20px);
  background-color: #00deff;
}
.logoImage {
  // animation: turn 12s 1s linear infinite;
}
.navMenu {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  color: #111111;
  animation: fadeFromLeft 1s ease-in-out;
  & .menuLink {
    font-weight: 700;
    margin: 0px 10px;
    // background: linear-gradient(90deg, #ffbc0011, #ffbc0066);
    background: none;
    @extend .polygonBg2;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(0.95);
    }
    &.active {
      // background: linear-gradient(90deg, #ffbc0055, #ffbc00ee);
      background: #ffbc00;
    }
  }
}

@keyframes logoAppears {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
