$red: #c00000;
$yellow: #ffbc00;
$purple: #7030a0;
$blue: #79d4ff;
$green: #5d8307;
$white: #eeeeee;
$black: #171717;
$pink: #fdbeef;
$dataBlue: #7cd9bb;
$lightYellow: #ffc000;
$darkPurple: #300047;
