.App {
  text-align: center;
  overflow: hidden;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: normal;
  background-color: white;
  background-image: url("./assets/background2.png");

  @media (orientation: portrait) {
    background-image: url("./assets/background2_portrait.png");
  }

  //   linear-gradient(45deg, #171717 70%, #ffbc00);
  background-position: center 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.5s;

  scrollbar-color: #111111 #ffbc00;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #111111;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ffa600;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #ffa600;
  }
}

.sectionTwo {
  // background-image: url("./assets/section2.jpg");
  background-color: mediumpurple;
  background-position: center;
  background-size: cover;
}

.notScrollableApp {
  overflow: hidden;
}

.backgroundVideo {
  position: fixed;
  left: auto;
  z-index: -1;

  @media (orientation: landscape) {
    // height: 100vh;
    width: 100vw;
    width: auto;
  }

  @media (orientation: portrait) {
    height: 100vh;
    width: auto;
    // right: -300px;
  }
}

/* Global styles*/
h1 {
  font-size: 30px;
  color: rgb(60, 60, 60);
  text-align: center;
  font-weight: lighter;
}

h2 {
  font-size: 0px;
  font-weight: 600;
  margin: 0px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
}

small {
  font-size: 10px;
}

button {
  border: none;
  outline: none;
  padding: 10px;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 5px;

  // font-family: "Fredoka One", cursive;
  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: default;
    opacity: 0.1 !important;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: white;
  margin: 50px 0px;

  &.dense {
    margin: 20px 0px;
  }

  &.invisible {
    background: transparent;
  }
}

.backPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // background: #232323;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  font-size: 500px;
  color: #171717aa;
  writing-mode: vertical-lr;
  line-height: 1em;
  // background: repeating-linear-gradient(0deg, black, transparent 5px),
  //   radial-gradient(circle at left bottom, #7030a0 70%, #ffbc00);
}

@media (orientation: landscape) {
  h1 {
    font-size: 40px;
  }
}

/* Useful shapes */
.polygonBg1 {
  padding: 10px 50px 10px 20px;
  clip-path: polygon(
    0% 50%,
    5px 5px,
    calc(100% - 20px) 0%,
    100% 50%,
    calc(100% - 20px) 100%,
    5px calc(100% - 5px)
  );
  transform-origin: -30px 30px;
  animation: fadePolygon 1s ease-in-out;

  & + .polygonBg1 {
    transform-origin: -30px -30px;
    transform: rotateZ(3deg) translateY(-5px);
    animation: fadePolygonSub 1s ease-in-out;
  }
}

.polygonBg2 {
  padding: 5px 20px;
  clip-path: polygon(
    5px 50%,
    0% 0%,
    calc(100% - 5px) 0%,
    100% 50%,
    calc(100% - 5px) 100%,
    0% 100%
  );
  // transform-origin: -30px 30px;
  // animation: fadePolygon 1s ease-in-out;
  // & + .polygonBg1 {
  //   transform-origin: -30px -30px;
  //   transform: rotateZ(3deg);
  //   animation: fadePolygonSub 1s ease-in-out;
  // }
}

.polygonBg3 {
  padding: 1px 20px;
  clip-path: polygon(5px 0%, 100% 0%, calc(100% - 5px) 100%, 0% 100%);
}

.polygonBg4 {
  padding: 1px 20px;
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 5px) 100%, 0% 100%);
}

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

.highlightedText {
  color: #ffbc00;
}

.highlightedText2 {
  color: #7030a0;
}

.infoBubble {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  transition: 0.3s all;
  z-index: 2;
  animation: lightFloat 2s ease-in-out infinite;
  box-shadow: 0px 0px 2px 5px #ffffff22;

  &:hover {
    opacity: 0.6;
  }
}

.exitInfoBubble {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  transition: 0.3s all;
  z-index: 2;
  box-shadow: 0px 0px 2px 5px #ffffff22;

  &:hover {
    opacity: 0.6;
  }
}

.dataStyleIcon {
  filter: brightness(1) invert(0.8) sepia(0.8) hue-rotate(100deg) saturate(200%);
}

.portraitPaddedPage {
  @media (orientation: portrait) {
    padding-top: 100px;
  }
}

/* Useful animations */

@keyframes turn {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeFromTop {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeFromRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeFromBottom {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadePolygon {
  from {
    opacity: 0;
    transform: rotateZ(-30deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

@keyframes fadePolygonSub {
  from {
    opacity: 0;
    transform: rotateZ(-30deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(3deg);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes softBlink {
  0% {
    opacity: 0.5;
    transform: scale(0.8);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.5;
    transform: scale(0.8);
  }
}

@keyframes softBounce {
  0% {
    opacity: 0.9;
    transform: scale(0.9);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0.9;
    transform: scale(0.9);
  }
}

@keyframes bookingButtonAnim {
  0% {
    opacity: 0.9;
    transform: scale(0.98);
    // box-shadow: 0px 5px 5px 2px rgba(255, 255, 255, 0.3);
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.7);

  }

  50% {
    opacity: 1;
    transform: scale(1.02);
    // box-shadow: 0px 15px 5px 5px rgba(255, 255, 255, 0.1);
    box-shadow: 0px 15px 5px 5px rgba(0, 0, 0, 0.1);

  }

  100% {
    opacity: 0.9;
    transform: scale(0.98);
    // box-shadow: 0px 5px 5px 2px rgba(255, 255, 255, 0.3);
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.7);

  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes lightFloat {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}

// .bookpage {
//   @media (orientation: portrait) {
//     width: 100%;
//   }
//   @media (orientation: landscape) {
//     width: 400px;
//     height: 710px;
//   }
//   border: 1px solid blue;
//   // background-image: url("./assets/secretBook/oldPage.png");
//   background-repeat: no-repeat;
//   background-size: cover;
// }
