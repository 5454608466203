@import "../../App.scss";
@import "../../variables.scss";

.statsDisplayer {
  width: 100%;
  height: 100%;
  //   padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;

  & .stats {
    position: relative;
    flex-grow: 1;
    width: calc(100% - 50px);
    height: 350px;
    padding: 10px 20px;
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    background: #050505;
    animation: tabAppear 0.3s ease-in-out;
    justify-content: center;
    @media (orientation: landscape) {
      max-height: 100%;
      // overflow: scroll;
    }
    & .infoBubble {
      background: black;
      border: 2px solid $dataBlue;
      color: $dataBlue;
      top: 10px;
      right: 10px;
    }
    & .exitInfoBubble {
      background: black;
      border: 2px solid transparent;
      color: $dataBlue;
      top: 10px;
      right: 10px;
      // filter: invert(1);
    }
  }

  & .statsRow {
    margin-top: 10px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
  }

  & .statsLabel {
    display: flex;
    padding: 0px 20px 0px 100px;
    background: linear-gradient(
      95deg,
      transparent 0%,
      transparent 10%,
      #eeeeee 11%,
      #eeeeee 15%,
      transparent 16%,
      transparent 20%,
      #eeeeee 21%,
      #eeeeee 25%,
      transparent 26%,
      transparent 30%,
      #eeeeee 31%,
      #eeeeee 90%,
      transparent 91%,
      transparent 100%
    );
    color: #171717;
    flex-grow: 1;
    width: 150px;
    animation: labelBgAnim 4s ease-in-out infinite;
    @extend .polygonBg3;
  }

  & .statsData {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    & .statsBar {
      display: inline-flex;
      height: 6px;
      border-radius: 3px;
      flex-grow: 1;
      background-color: #232323;
      margin: 0px 10px;
      animation: barAppears 1s ease-in-out;
    }
  }

  & .stats1 {
    border-radius: 3px;
    & .statsLabel {
      padding: 0px 20px 0px 100px;
      background: linear-gradient(
        95deg,
        transparent 0%,
        transparent 10%,
        #ffbc00 11%,
        #ffbc00 15%,
        transparent 16%,
        transparent 20%,
        #ffbc00 21%,
        #ffbc00 25%,
        transparent 26%,
        transparent 30%,
        #eeeeee 31%,
        #eeeeee 90%,
        transparent 91%,
        transparent 100%
      );
    }
    //   background: rgba(0, 0, 0, 0.8);
    // border: 1px solid white;
  }

  & .stats2 {
    @media (orientation: landscape) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      & .statsRow {
        width: 45%;
      }
      & .statsLabel {
        width: auto;
      }
    }
    & .statsLabel {
      background: linear-gradient(
        95deg,
        transparent 0%,
        transparent 10%,
        #7030a0 11%,
        #7030a0 15%,
        transparent 16%,
        transparent 20%,
        #7030a0 21%,
        #7030a0 25%,
        transparent 26%,
        transparent 30%,
        #eeeeee 31%,
        #eeeeee 90%,
        transparent 91%,
        transparent 100%
      );
    }
  }

  & .worldStats {
    & .statsLabel {
      background: linear-gradient(
        95deg,
        transparent 0%,
        transparent 10%,
        $green 11%,
        $green 15%,
        transparent 16%,
        transparent 20%,
        $green 21%,
        $green 25%,
        transparent 26%,
        transparent 30%,
        #eeeeee 31%,
        #eeeeee 90%,
        transparent 91%,
        transparent 100%
      );
    }
  }

  & & .idData {
    flex-direction: row;
    align-items: center;
    @media (orientation: portrait) {
      flex-direction: column;
    }
  }

  & .idStats {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
  }

  & .statsMenu {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-self: flex-end;
    & .statsMenuButton {
      width: 25%;
      height: 50px;
      background: #101010;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0px;
      &:first-child {
        border-bottom-left-radius: 5px;
      }
      @media (orientation: portrait) {
        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }
      & > img {
        height: 30px;
        filter: invert(0.8);
        @media (orientation: portrait) {
          height: 25px;
        }
      }
      &.active {
        background: #050505;
        & img {
          filter: brightness(1) invert(0.8) sepia(0.8) hue-rotate(100deg)
            saturate(200%);
        }
      }
    }
  }
}

@keyframes tabAppear {
  0% {
    opacity: 0;
    clip-path: polygon(0% 0%, 40% 0%, calc(40% - 5px) 100%, 0px 100%);
    // transform: scale(0.9) rotateY(25deg);
  }
  90% {
    opacity: 0.6;
    clip-path: polygon(0% 0%, 100% 0%, calc(100% - 5px) 100%, 0px 100%);
  }
  100% {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, calc(100% - 5px), 0px 100%);
    // transform: scale(1) rotateY(0deg);
  }
}

@keyframes barAppears {
  0% {
    background-position: -50px center;
  }
  100% {
    background-position: 0px center;
  }
}

@keyframes statBarBlink {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.5);
  }
  100% {
    filter: brightness(1);
  }
}

@keyframes labelBgAnim {
  0% {
    background-position-x: 0px;
  }
  50% {
    background-position: 2px;
  }
  100% {
    background-position: 0px;
  }
}
