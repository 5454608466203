@import "../../App.scss";
@import "../../variables.scss";

.cardDetailsWrapper {
  display: flex;
  flex-direction: row;
  background-color: #171717;
  border-radius: 5px;
  position: relative;
  margin-top: 5px;
  width: 100%;
  height: 400px;

  // width: 100%;
  // height: 100%;
  & .cardImage {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #050505;
    margin: 10px;
    overflow: hidden;

    & img {
      animation: cardTurns 5s ease-in-out infinite;
      cursor: pointer;
    }
    & .cardImageTip {
      display: none;
      position: absolute;
    }
    @media (orientation: portrait) {
      position: absolute;
      width: 30px;
      height: 60px;
      top: 0px;
      left: 0px;
      background-color: transparent;
      overflow: visible;
    }
  }
}

.cardStatsDisplayer {
  width: 100%;
  height: 100%;
  //   padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;

  & .stats {
    position: relative;
    flex-grow: 1;
    width: calc(100% - 50px);
    height: 350px;
    padding: 10px 20px;
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    background: #050505;
    animation: tabAppear 0.3s ease-in-out;
    scrollbar-color: $dataBlue #000000;

    @media (orientation: landscape) {
      max-height: 100%;
      // overflow: scroll;
    }

    & .infoZone {
      overflow-y: scroll;
    }

    & .infoBubble {
      background: black;
      border: 2px solid $dataBlue;
      color: $dataBlue;
      top: 10px;
      right: 10px;
    }

    & .exitInfoBubble {
      background: black;
      border: 2px solid transparent;
      color: $dataBlue;
      top: 10px;
      right: 10px;
      // filter: invert(1);
    }
  }

  & .statsRow {
    margin-top: 10px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
  }

  & .statsTitle {
    font-size: 20px;
    font-weight: 700;
    background: #232323;
    display: flex;
    padding: 0px 20px 0px 20px;
    margin-bottom: 10px;
    color: #505050;
    animation: labelBgAnim 4s ease-in-out infinite;
    @extend .polygonBg3;
  }

  & .statsLabel {
    display: flex;
    padding: 0px 20px 0px 100px;
    background: linear-gradient(
      95deg,
      transparent 0%,
      transparent 10%,
      #eeeeee 11%,
      #eeeeee 15%,
      transparent 16%,
      transparent 20%,
      #eeeeee 21%,
      #eeeeee 25%,
      transparent 26%,
      transparent 30%,
      #eeeeee 31%,
      #eeeeee 90%,
      transparent 91%,
      transparent 100%
    );
    color: #171717;
    flex-grow: 1;
    width: 150px;
    overflow: hidden;
    animation: labelBgAnim 4s ease-in-out infinite;
    @extend .polygonBg3;
  }

  & .statsData {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;

    & .statsBar {
      display: inline-flex;
      height: 6px;
      border-radius: 3px;
      flex-grow: 1;
      background-color: #232323;
      margin: 0px 10px;
      animation: barAppears 1s ease-in-out;
    }
  }

  & .stats1 {
    border-radius: 3px;

    & .statsLabel {
      padding: 0px 20px 0px 100px;
      background: linear-gradient(
        95deg,
        transparent 0%,
        transparent 10%,
        #ffbc00 11%,
        #ffbc00 15%,
        transparent 16%,
        transparent 20%,
        #ffbc00 21%,
        #ffbc00 25%,
        transparent 26%,
        transparent 30%,
        #eeeeee 31%,
        #eeeeee 90%,
        transparent 91%,
        transparent 100%
      );
    }
  }

  & .stats2 {
    & .statsLabel {
      background: linear-gradient(
        95deg,
        transparent 0%,
        transparent 10%,
        #7030a0 11%,
        #7030a0 15%,
        transparent 16%,
        transparent 20%,
        #7030a0 21%,
        #7030a0 25%,
        transparent 26%,
        transparent 30%,
        #eeeeee 31%,
        #eeeeee 90%,
        transparent 91%,
        transparent 100%
      );
    }
  }

  & .worldStats {
    & .statsLabel {
      background: linear-gradient(
        95deg,
        transparent 0%,
        transparent 10%,
        $green 11%,
        $green 15%,
        transparent 16%,
        transparent 20%,
        $green 21%,
        $green 25%,
        transparent 26%,
        transparent 30%,
        #eeeeee 31%,
        #eeeeee 90%,
        transparent 91%,
        transparent 100%
      );
    }
  }

  & .idStats {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }

  & .statsMenu {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-self: flex-end;

    & .statsMenuButton {
      width: 25%;
      height: 50px;
      background: #101010;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0px;

      &:first-child {
        border-bottom-left-radius: 5px;
      }

      @media (orientation: portrait) {
        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }

      & > img {
        height: 30px;
        filter: invert(0.8);

        @media (orientation: portrait) {
          height: 25px;
        }
      }

      &.active {
        background: #050505;

        & img {
          filter: brightness(1) invert(0.8) sepia(0.8) hue-rotate(100deg)
            saturate(200%);
        }
      }
    }
  }
}

@keyframes cardTurns {
  0% {
    transform: rotate3d(1, 1, 1, 10deg);
  }

  20% {
    transform: rotate3d(1, 1, 1, -10deg);
  }

  40% {
    transform: rotate3d(0, 1, 1, -20deg);
  }

  60% {
    transform: rotate3d(1, 1, 1, -20deg);
  }

  100% {
    transform: rotate3d(1, 1, 1, 10deg);
  }
}
