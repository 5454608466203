@import "../../App.scss";

$backgroundColor: #81c3fc;
$littoralColor: #81c3fc;
$deepWaterColor: #0076af;
$unknowCellColor: $deepWaterColor;
$landColor: #ffdb88;
$landColorDark: #8a6066;

.mapAndItemsWrapper {
  position: relative;
  background-color: lightblue;
  width: 100%;
  margin-top: 10px;
  @media (orientation: landscape) {
    height: 70vh;
    max-height: 70vh;
    transform: translateX(-100px);
  }
  @media (orientation: portrait) {
    // height: 70vh;
    // max-height: 70vh;
    height: 40vh;
    max-height: 40vh;
  }
}

.mapContainer {
  //   position: relative;
  //   background-color: green;
  //   overflow: hidden;
  width: 100%;
  height: 100%;
  animation: fade 1s ease-in-out;
  //   margin-top: 10px;
  //   @media (orientation: landscape) {
  //     height: 70vh;
  //     max-height: 70vh;
  //     transform: translateX(-50px);
  //   }
  //   @media (orientation: portrait) {
  //     height: 70vh;
  //     max-height: 70vh;
  //   }
}

.mapTools {
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 5px;
  opacity: 0.8;
  display: flex;
  gap: 5px;
  animation: fadeFromTop 2s ease-in-out;
  & > button {
    // margin: 5px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      transition: 0.2s ease-in-out all;
    }
    &:hover {
      & > svg {
        transform: scale(0.8);
      }
    }
  }
  //   @media (orientation: landscape) {
  //     display: flex;
  //     flex-direction: column;
  //   }
  //   @media (orientation: portrait) {
  //   }
}

.wholeMap {
  width: 100%;
  height: 100%;
  //   background: linear-gradient(90deg, blue, red);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @media (orientation: landscape) {
    //     width: 300px;
    //     height: 100%;
    //   }
  }
  & .mapCell {
    cursor: pointer;
    position: relative;
    margin: 1px;
    background: $backgroundColor;
    border-radius: 40%;
    transition: 0.1s ease-in-out all;
    animation: fade 1s ease-in-out;
    & > svg {
      position: absolute;
      width: 120%;
      top: -10%;
      left: -10%;
      display: none;
      transition: 0.1s ease-out all;
    }
    & > .cellIcon {
      position: absolute;
    }
    &.land {
      background-color: $landColor;
    }
    &.littoral {
      background-color: $littoralColor;
    }
    &.deepWater {
      background-color: $deepWaterColor;
      transform: scale(0.8);
    }
    &.unknown {
      background-color: $unknowCellColor;
    }
    &:hover {
      animation: softBounce 2s ease-in-out infinite;
      & > svg {
        opacity: 0.5;
        display: block;
      }
    }
    &.focusedCell {
      z-index: 2;
      animation: softBounce 2s ease-in-out infinite;
      & > svg {
        display: block;
        filter: invert(1);
      }
    }
  }
}

.contextBlock {
  position: absolute;
  top: 0px;
  z-index: 2;
  width: calc(100% - 20px);
  height: calc(90% - 20px);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  @media (orientation: landscape) {
    width: 300px;
    height: 70vh;
    right: -330px;
    animation: fadeFromRight 1s ease-in-out;
  }
  @media (orientation: portrait) {
    position: relative;
    margin-top: 10px;
    animation: fadeFromBottom 1s ease-in-out;
    height: fit-content;
    padding-bottom: 20px;
  }
  & > h2 {
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    background-color: $backgroundColor;
    color: white;
    animation: fadeFromLeft 0.5s ease-out;
    font-size: 16px;
    font-weight: bold;
    &.land {
      background-color: $landColorDark;
    }
    &.littoral {
      background-color: $littoralColor;
    }
    &.deepWater {
      background-color: $deepWaterColor;
    }
    &.unknown {
      background-color: $unknowCellColor;
    }
  }
  & .coordinatesBadge {
    border-radius: 5px;
    margin-left: 10px;
    padding: 4px;
    background: white;
    border: 1px solid #171717;
    color: #171717;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    font-size: 10px;
    font-weight: bold;
    width: fit-content;
    height: fit-content;
  }
  & .contextPicture {
    animation: fade 0.2s ease-out;
    @media (orientation: landscape) {
      margin-top: 10px;
    }
  }
  & .contextDescription {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
    font-size: 14px;
  }
}
