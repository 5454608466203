.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: #171717;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  animation: fadeFromBottom 1s ease-in-out;
  transition: 1s all;
  width: calc(100% - 20px);

  @media (orientation: landscape) {
    margin-top: 20px;
  }

  & .collectibleCard {
    position: relative;
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
    opacity: 0;
    transition: 0.3s all;
    animation: fadeFromLeft 0.5s ease-in-out forwards;

    &>img {
      width: 100px;
      height: 144px;
    }

    &:hover {
      box-shadow: 0px 0px 5px 5px #ffffff33;
      // transform: scale(1.1);
    }
  }

  &.dense {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  &:not(.dense) {
    @media (orientation: landscape) {
      & .collectibleCard>img {
        width: 250px;
        height: 360px;
      }
    }
  }


  scrollbar-color: #111111 #ffbc00;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    // width: 10px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #111111;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ffa600;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #ffa600;
  }
}

// .focusedCollectibleCard {
//   max-width: 400px;
//   animation: cardTurns 10s ease-in-out infinite;
// }

.cardDisplayer {
  position: relative;
}

.cardDisplayer .cardElement {
  // 1.44 facteur
  width: 300px;
  height: 433px;
  border-radius: 10px;

  @media (orientation: landscape) {
    width: 350px;
    height: 504px;
  }

  &:not(.turned) {
    // animation: cardTurns 10s ease-in-out infinite;
  }

  & .cardFace {
    border-radius: 10px;
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  & .cardRecto {
    color: red;
    transition: 1s all;
    animation: shadowBlinkRecto 5s ease-in-out infinite;
    // transform: rotateY(180deg);
  }

  & .cardVerso {
    text-align: left;
    transition: 1s all;
    position: absolute;
    background: #171717;
    font-size: 14px;
    width: calc(100% - 20px);
    height: calc(100% - 40px);
    overflow: hidden;
    color: #eeeeee;
    padding: 20px 10px;
    transform: rotateY(180deg);
    animation: shadowBlinkVerso 5s ease-in-out infinite;
    // border-radius: 5px;
  }

  & ul {
    padding-left: 20px;
    margin: 0px;
  }
}

.cardDisplayer .cardElement.turned {
  & .cardRecto {
    transform: rotateY(180deg);
  }

  & .cardVerso {
    transform: rotateY(360deg);
  }
}

.rotateButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -80px;
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background: #171717;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 5px;
  cursor: pointer;
  animation: softBlink 5s ease-in-out infinite;

  &:hover {
    box-shadow: 0px 0px 5px 5px #ffffff33;
  }

  & .rotateIcon {
    filter: brightness(1) invert(0.8) sepia(0.8) hue-rotate(100deg) saturate(200%);
  }
}

.openModalButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #171717dd;
  position: absolute;
  bottom: 0px;
  right: 0px;

  & .zoomIcon {
    transform: scale(1);
    transition: 0.5s all;
    filter: brightness(1) invert(0.8) sepia(0.8) hue-rotate(100deg) saturate(200%);
  }

  &:hover {
    box-shadow: 0px 0px 5px 5px #ffffff33;

    & .zoomIcon {
      transform: scale(0.8);
    }
  }
}

@keyframes shadowBlinkRecto {
  0% {
    box-shadow: 0px 0px 40px 1px #ffffff22;
  }

  50% {
    box-shadow: 0px 0px 40px 1px #ffffff44;
  }

  100% {
    box-shadow: 0px 0px 40px 1px #ffffff22;
  }
}

@keyframes shadowBlinkVerso {
  0% {
    box-shadow: 0px 0px 40px 1px #7cd9bb22;
  }

  50% {
    box-shadow: 0px 0px 40px 1px #7cd9bb44;
  }

  100% {
    box-shadow: 0px 0px 40px 1px #7cd9bb22;
  }
}