@import "../../App.scss";
@import "../../variables.scss";

.playersContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scrollbar-width: thin;
  justify-content: flex-start;
  background: #050505dd;
  padding: 10px;
  border-radius: 5px;

  @media (orientation: portrait) {
    max-width: calc(100% - 20px);
  }
  @media (orientation: landscape) {
    padding: 20px;
  }
  // box-shadow: 0px 0px 5px 5px #ffffff33;
  & .playerCard {
    border-radius: 5px;
    margin: 5px;
    background: #171717;
    height: 60px;
    width: 60px;
    min-width: 60px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 1px 4px transparent;
    @media (orientation: landscape) {
      height: 80px;
      width: 80px;
      margin: 10px;
    }
    &:hover {
      box-shadow: 0px 0px 2px 2px #ffffff11;
    }
    &.focusedCard {
      // border: 1px solid red;
      filter: sepia(1);
      box-shadow: 0px 0px 10px 2px #7cd9bb99;
    }
  }
  transition: 0.5s all;
  animation: fadeFromBottom 2s ease-in-out;
}

.playerFocus {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px;
  width: calc(100%);
  // height: 300px;
  background: #171717;
  border-radius: 5px;
  // box-shadow: 0px 0px 5px 5px #ffffff33;
  @media (orientation: portrait) {
    height: auto;
  }
  & .playerName {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    background: #171717;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 20px;
    color: white;
    text-decoration: overline;
    animation: nameAppears 1s ease-in-out;
    @extend .polygonBg4;
    // @media (orientation: landscape) {
    //   background: #171717;
    // }
  }

  & .infoZone {
    margin-top: 30px;
  }
  animation: fade 1s ease-in-out;
}

.playerVideo {
  position: relative;
  width: 100%;
  // height: 100%;
  overflow: hidden;
  background: black;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  border: 3px solid white;
  @media (orientation: portrait) {
    height: 100%;
    background-position: center -80px;
  }
  @media (orientation: landscape) {
    width: 500px;
  }
  // TODO : delete  when we have real wideo
  // & > img {
  //   @media (orientation: portrait) {
  //     height: 100%;
  //     width: auto;
  //   }
  //   @media (orientation: landscape) {
  //     width: 100%;
  //     height: auto;
  //   }
  //   animation: float 2s ease-in-out infinite;
  // }
}
