.drawerButton {
  width: 30px;
  height: 30px;
  & > .line {
    width: 20px;
    height: 2px;
    margin: 7px 5px;
    // background: white;
    background: #111111;
    transition: all 0.3s ease-in-out;
  }

  &.open > .line:first-child {
    transform: rotateZ(45deg) translateY(5px);
  }
  &.open > .line:nth-child(2) {
    transform: rotateZ(-45deg) translateY(-5px);
  }
}
