.bannerContainer {
  width: 100%;
  max-width: 800px;
  position: relative;

  &::before {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    left: -8px;
    top: calc(50% - 5px);
    z-index: 1;
    box-shadow: 0px 0px 5px 5px #ffffff55;
  }

  &::after {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    right: -8px;
    top: calc(50% - 5px);
    z-index: 1;
    box-shadow: 0px 0px 3px 3px #ffffff55;
  }
}

.bannerBorderContainer {
  width: calc(100% - 4px);
  overflow: hidden;
  position: relative;
  clip-path: polygon(0% 50%,
      5px 0%,
      calc(100% - 5px) 0%,
      100% 50%,
      calc(100% - 5px) 100%,
      5px 100%);
  background: linear-gradient(45deg, #ffbc00, white, #ffbc00);
  background-position: 0px 0px;
  padding: 2px;
  animation: borderAnimation 3s ease-in-out infinite, fadeFromTop 1s ease-in-out;
}

.banner {
  width: calc(100% - 20px);
  text-align: center;
  background: linear-gradient(45deg, #eeeeee, #eeeeee, #eeeeee);
  color: #171717;
  padding: 5px 10px;
  position: relative;
  clip-path: polygon(0% 50%,
      5px 0%,
      calc(100% - 5px) 0%,
      100% 50%,
      calc(100% - 5px) 100%,
      5px 100%);

  @media (orientation: landscape) {
    font-size: 15px;
  }

  //   animation: textAnimation 5s ease-in-out infinite;
}

// @keyframes bannerAppear {
//   0% {
//     opacity: 0;
//     transform: scaleX(0);
//   }
//   //   50% {
//   //     background-position: 600px 0px;
//   //   }
//   100% {
//     opacity: 1;
//     transform: scaleX(1);
//   }
// }

.scrollingBanner {
  width: fit-content;
  position: relative;
  white-space: nowrap;
  animation: scrollAnimation 10s linear infinite;
}

.secondText {
  display: inline;
  white-space: nowrap;
  position: absolute;
  left: 100%;
  top: 0%;
  animation: scrollAnimation 10s 10s linear infinite;
}

@keyframes borderAnimation {
  0% {
    background-position: 0px 0px;
  }

  50% {
    background-position: 600px 0px;
  }

  100% {
    background-position: 0px 0px;
  }
}

@keyframes textAnimation {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

// @keyframes scrollAnimation {
//   0% {
//     transform: translateX(100%);
//   }

//   100% {
//     transform: translateX(-100%);
//   }
// }