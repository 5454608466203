@import "../../App.scss";
@import "../../variables.scss";

// CAREFUL THIS FILE IS IN LF BECAUSE CRLF STARTED BREAKING CSS -- THIS SHOULD BE FIXED
$backgroundColor: #81c3fc;
$littoralColor: #81c3fc;
$deepWaterColor: #0076af;
$unknowCellColor: $deepWaterColor;
$landColor: #ffdb88;
$landColorDark: #8a6066;

.videoAndBannersContainer {
  display: flex;
  padding-top: 30px;
  @media (orientation: portrait) {
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
  }

  @media (orientation: landscape) {
    flex-direction: column;
    // transform: translate(500px, -200px);
    // position: absolute !important;
    // right: 50px !important;
    // bottom: 50px;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
  }

  .videoTitle {
    text-align: left;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: #ffffff; //rgba(121, 212, 255, 0.9);
    border: 2px solid #000000; // rgb(121, 212, 255);
    color: 000000;
    font-size: 25px;
    // width: 100%;
    margin-bottom: 25px;
    position: relative;
    .arrowDown {
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #ffffff;
      position: absolute;
      left: calc(50% - 10px);
      bottom: -20px;
      z-index: 1;
    }
    .arrowDown2 {
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 30px solid #000000;
      position: absolute;
      left: calc(50% - 23px);
      bottom: -30px;
    }
  }
}

.hintDown {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background: url("../../assets/icons/arrowDown.png");
  background-position: center center;
  background-size: contain;
  background-color: #000000aa;
  margin-top: 20px;
  animation: float ease-in-out 1s infinite;
}

.bannerWrappper {
  // @media (orientation: portrait) {
  //   margin-top: 30px;
  // }

  // @media (orientation: landscape) {
  //   position: absolute !important;
  //   right: 50px !important;
  //   bottom: 50px;
  //   margin-left: 0px
  // }
  border-radius: 10px;
  background-color: #111111;
  padding: 15px 15px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (orientation: portrait) {
    width: 90vw;
    padding: 15px 15px;
  }
}

.videoWrapper {
  // @media (orientation: portrait) {
  //   margin-top: 30px;
  // }

  // @media (orientation: landscape) {
  //   position: absolute !important;
  //   right: 50px !important;
  //   bottom: 50px;
  //   margin-left: 0px
  // }
  border-radius: 10px;
  background-color: rgba(121, 212, 255, 0.5);
  border: 2px solid rgb(121, 212, 255);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.welcomeBigtext {
  font-weight: bold;
  font-size: 50px;
  color: white;
  position: relative;
  user-select: none;
  // text-shadow: 5px 5px 5px black;
  @media (orientation: portrait) {
    margin-top: 300px;
    font-size: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (orientation: landscape) {
    transform: translate(-300px, 500px);
    // position: absolute;
    // left: 50px;
    // top: 100px;
  }
  // & .welcomeBigText2 {
  //   transform: skew(10deg, 0deg);
  //   -webkit-transform: skew(-200deg, 200deg);
  //   padding: 10px;
  //   background-color: rgb(10, 10, 10);
  // }
}

.welcomeBigText1 {
  font-family: "Bungee";
  font-size: 42px;
  text-shadow:
    3px 3px 0 #000,
    -2px -2px 0 #000,
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000;
}

.welcomeBigText3 {
  display: inline-flex;

  // transform: translateY(300px);
  @media (orientation: landscape) {
    transform: translate(20px, 20px) skewX(-10deg);
    font-size: 55px;
  }

  @media (orientation: portrait) {
    transform: skewX(-10deg);
    font-size: 40px;
  }

  padding: 0px 100px;
  // background-color: #ffbc00;
  background: url("../../assets/stylizedTextBackground2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  // border-radius: 50px;
  // padding: 10px 50px;
  // font-style: italic;
  display: inline-flex;
  color: #ff3200;
  text-shadow:
    0px 0px 0 #fff,
    -2px -2px 0 #fff,
    2px -2px 0 #fff,
    -2px 2px 0 #fff,
    2px 2px 0 #fff;
  // box-shadow: 5px 5px 5px black;
}

.welcomeBigText4 {
  display: inline-flex;
  // border-radius: 50px;
  // font-style: italic;
  padding: 10px 10px;
  background-color: #00deff;
  transform: skewX(-10deg);
  box-shadow: 1px 1px 1px #111111;
  height: fit-content;
}

.welcomeBigText5 {
  display: block;

  transform: translate(50px, -5px) skewX(-10deg);
  @media (orientation: landscape) {
    // transform: translate(-180px, 100px) skewX(-10deg);
    font-size: 55px;
  }

  @media (orientation: portrait) {
    // transform: skewX(-10deg);
    font-size: 40px;
  }

  padding: 0px 10px;
  background-color: #ffbc00;
  // border-radius: 50px;
  // padding: 10px 50px;
  // font-style: italic;
  display: inline-flex;
  color: #ff3200;
  text-shadow:
    0px 0px 0 #fff,
    -2px -2px 0 #fff,
    2px -2px 0 #fff,
    -2px 2px 0 #fff,
    2px 2px 0 #fff;
  box-shadow: 2px 2px 2px black;
}

.welcomeBigText2 {
  display: inline-flex;
  // border-radius: 50px;
  // font-style: italic;
  padding: 10px 50px;
  // background-color: #00deff;
  transform: skewX(-10deg);
  // box-shadow: 5px 5px 5px black;
  background: url("../../assets/stylizedTextBackground.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.portraitCR {
  @media (orientation: landscape) {
    display: none;
  }
}

// .trailerContainer {
//   position: absolute;
//   right: 50px;
// }

.caption {
  width: fit-content;
  opacity: 0;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 12px;
  padding: 10px 10px;
  border-radius: 5px;
  color: white;
  font-weight: normal;
  background-color: rgba(150, 0, 0, 0.9);
  box-shadow: white 0px 0px 10px 2px;

  animation: softBounce ease-in-out 5s infinite;
}

.twitchButton {
  background-color: #ffdb88;
  width: 100%;
  font-size: 18px;
  padding: 10px 10px;
  border-radius: 5px;
  color: #111111;
  // transform: skewX(-5deg);
  border: 1px solid white;
  box-shadow: 0px 10px 0px 5px rgba(255, 255, 255, 0.2);
  animation: bookingButtonAnim ease-in-out 4s infinite;
  max-width: 500px;
  font-weight: bold;
}

$bookingtextoutline: black;

.bookingButton {
  z-index: 100;
  font-family: "Titillium Web", sans-serif;
  cursor: pointer;
  @media (orientation: portrait) {
    // padding: 10px 5px 10px 50px;
    padding-left: 70px;
    padding-right: 30px;
    background-position: 20px 5px;
    width: fit-content;
    margin-top: 50px;
  }
  width: 100%;
  // margin-top: 10px;
  // margin-bottom: 20px;
  font-size: 24px;
  padding: 10px 10px;
  border-radius: 50px;
  // color: #00deff;
  color: #fff9e3;
  background-color: #ff0095;
  // transform: skewX(-5deg);
  // border: 5px solid white;
  box-shadow: 0px 10px 0px 5px rgba(255, 255, 255, 0.2);
  // animation: bookingButtonAnim ease-in-out 2s infinite;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.5);
  background-image: url("../../assets/bookingButtonBg.png");
  background-repeat: no-repeat;
  background-position: 70px bottom;
  background-size: contain;
  max-width: 500px;
  font-weight: bold;
  @media (orientation: landscape) {
    position: fixed;
    bottom: 50px;
    right: 50px;
    padding: 10px 10px;
  }
  .btnHint {
    // font-family: "Bungee";

    font-size: 14px;
  }
  // text-shadow:
  //   1px 1px 0 $bookingtextoutline,
  //   -1px 1px 0 $bookingtextoutline,
  //   -1px -1px 0 $bookingtextoutline,
  //   1px -1px 0 $bookingtextoutline;
}

.bookingButtonDisabled {
  animation: none;
  cursor: default;
  border: none;
}

.bottomBanner {
  display: flex;
  background-color: white;
  width: 100vw;
  z-index: 100;
  border-top: 1px solid #00deff;
  @media (orientation: portrait) {
    // position: fixed;
    // bottom: 0px;
    // left: 0px;
    // bottom: 0px;
    // transform: translate(-20px, 100px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (orientation: landscape) {
    position: fixed;
    bottom: 0px;
    left: 0px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    button:last-child {
      margin-right: 50px;
    }
  }

  button {
    font-size: 12px;
    background: none;
    color: #111111;
    text-decoration: underline;
  }
}

.firstPagePlaceholder {
  @media (orientation: landscape) {
    position: relative;
    height: 60vh;
  }
  @media (orientation: portrait) {
    position: relative;
    height: 0vh;
  }
}
.sectionOne {
  margin-top: 100px;
  @media (orientation: portrait) {
    margin-top: 0px;
  }
  margin-bottom: 150px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // @media (orientation: landscape) {
  //   position: relative;
  //   height: 100vh;
  // }
}

.sectionTwo {
  // height: 100vh;
  width: 100vw;
  // background-color: rgba(121, 212, 255, 0.95);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid white;
  border: 1px solid rgba(121, 212, 255, 1);
  // background-color: wheat;
  color: #111111;
  font-size: 18px;
  text-align: left;
  @media (orientation: landscape) {
    // width: calc(100vw - 200px);
    padding: 20px;
    text-align: left;
  }

  @media (orientation: portrait) {
    width: 100%;
    padding: 50px 80px;
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    padding-bottom: 50px;
  }
}

// .bookContainer {
//   display: flex;
//   @media (orientation: landscape) {
//     flex-direction: row;
//     overflow-x: scroll;
//   }

//   @media (orientation: portrait) {
//     // flex-direction: column;
//     overflow-x: scroll;
//   }
// }
// .bookpage {
//   display: flex;
//   text-align: left;
//   @media (orientation: portrait) {
//     height: 80vh;
//     min-height: 80vh;
//     width: 80vw;
//     min-width: 80vw;
//     // margin-bottom: 20px;
//   }
//   @media (orientation: landscape) {
//     margin-right: 20px;
//     min-width: 400px;
//     width: 400px;
//     height: 710px;
//   }
//   color: white;
//   border: 2px solid white;
//   // background: #000000cc;
//   background: linear-gradient(120deg, #000000cc, #000000dd 40%, #000000cc);
//   padding: 20px 40px;
//   background-repeat: no-repeat;
//   background-size: cover;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   position: relative;
//   .pageHeader {
//     width: 100%;
//     display: flex;
//     align-self: flex-start;
//     justify-content: center;
//     text-align: center;
//   }
//   .pageFooter {
//     display: flex;
//     width: 100%;
//     justify-content: center;
//     text-align: center;
//   }
//   .footNote {
//     display: flex;
//     font-size: 11px;
//     margin-top: auto;
//     position: absolute;
//     bottom: 20px;
//   }
// }

// .pageContent {
//   height: 50%;
//   min-height: 50%;
//   align-content: center;
// }

// .pageNoteNumber {
//   // display: contents;
//   // position: relative;
//   font-size: 11px;
//   // font-variant-position: super;
//   // transform: translateY(-10px);
// }

.presenter {
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // margin-bottom: 30px;
  .presenterText {
    width: fit-content;
    z-index: 10;
  }
  .presenterSection {
    display: flex;
    flex-direction: column;
    font-size: 40px;
    text-align: center;
  }
  .presenterSection:nth-child(odd) {
    align-self: flex-start;
    .presenterText {
      align-self: flex-start;
    }
  }
  .presenterSection:nth-child(even) {
    align-self: flex-end;
    .welcomeBigText5 {
      transform: translate(-50px, -5px) skewX(-10deg);
    }
    .presenterText,
    .explainImage {
      align-self: flex-end;
    }
  }
  @media (orientation: landscape) {
    padding: 50px 200px 0px 200px;

    .presenterSection:nth-child(2) {
      transform: translateY(-100px);
    }
    .presenterSection:nth-child(3) {
      transform: translateY(-200px);
    }

    // margin-bottom: 300px;
  }
  @media (orientation: portrait) {
    flex-direction: column;
    align-items: flex-start;

    .presenterSection {
      text-align: left;
      margin: 20px 0px;
      font-size: 25px;
    }
    .presenterSection:nth-child(even) {
      align-self: flex-end;
    }
  }
}

.explainImage {
  border: 1px solid $blue;
  transform: translateY(-20px);
  display: flex;
  @media (orientation: landscape) {
    width: 50%;
  }
  @media (orientation: portrait) {
    width: 100%;
  }
}
