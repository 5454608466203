@import "../../variables.scss";

$paddingPage: 20px;

.mobileLandscapeMessage {
  display: none;
  @media (orientation: landscape) and (max-width: 1200px) {
    display: flex !important;
  }
}
.streamPage {
  position: relative;
  background: #171717;
  //   width: 100vw;
  //   height: 100vh;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  color: white;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: top right;
  animation: bgAppears 1s ease-in-out;
  & .pageContent {
    // Quand on le set à 10, les border bottom des vidéos disparaissent, souci d'espace disponible
    // padding: 9px;
    position: relative;
    display: flex;
    // max-width: 800px;
    // max-width: 1280px;
    // max-height: 720px;
    width: calc(100vw - 40px);
    height: calc((100vw - 40px) / 1.78);
    @media (orientation: landscape) and (min-width: 1200px) {
      width: 80vw;
      height: calc(80vw / 1.78);
      //   transform: rotateZ(90deg);
    }
    @media (orientation: landscape) and (max-width: 1200px) {
      display: none !important;
    }
  }
  & .videoContainer {
    transition: all 0.3s ease-in-out;
    & video {
      background-color: #050505;
      transition: all 0.3s ease-in-out;
    }
    &.focusedVideo {
      width: 100%;
      height: fit-content;
      top: 0px;
      left: 0px;
    }
    &:not(.focusedVideo) {
      cursor: pointer;
      opacity: 0.5;
      width: 45%;
      height: fit-content;
      @media (orientation: landscape) {
        width: 100px;
      }
    }
    &:not(.focusedVideo):hover {
      opacity: 1;
      transform: scale(0.95);
      //   box-shadow: 0px 0px 5px 5px rgba($dataBlue, 0.5);
    }
  }

  &.test {
    border: 10px solid #050505;
  }

  // Views

  $pageAppearsDuration: 1s;

  & .masterVideo {
    position: absolute;
    & video {
      border: 1px solid #ffffff;
    }
    &::before {
      content: "Master";
      background: #ffffffdd;
      border-radius: 3px;
      font-size: 14px;
      padding: 4px 4px;
      line-height: 10px;
      color: #171717;
      position: absolute;
      left: 10px;
      top: -25px;
    }
    animation: fade 3s ease-out;
  }

  & .IRLPlayer1Video {
    position: absolute;
    &::before {
      content: "IRL_1";
      background: rgba($green, 0.8);
      border-radius: 3px;
      font-size: 14px;
      padding: 4px 4px;
      line-height: 10px;
      color: white;
      position: absolute;
      left: 10px;
      top: -20px;
    }
    & video {
      border: 1px solid $green;
    }
    @media (orientation: portrait) {
      top: -70%;
      left: 0px;
      @media (max-height: 1500px) and (min-width: 1000px) {
        top: -50%;
      }
    }
    @media (orientation: landscape) {
      top: 20px;
      left: calc(100% + 10px);
    }
    animation: screenAppears1 2s ease-out;
  }

  & .IRLPlayer2Video {
    position: absolute;
    &::before {
      content: "IRL_2";
      background: rgba($purple, 0.8);
      border-radius: 3px;
      font-size: 14px;
      padding: 4px 4px;
      line-height: 10px;
      color: white;
      position: absolute;
      left: 10px;
      top: -20px;
    }
    & video {
      border: 1px solid $purple;
    }
    @media (orientation: portrait) {
      top: -70%;
      left: 55%;
      @media (max-height: 1500px) and (min-width: 1000px) {
        top: -50%;
      }
    }
    @media (orientation: landscape) {
      top: 120px;
      left: calc(100% + 10px);
    }
    animation: screenAppears1 2s ease-out;
  }
  & .inGamePlayer1Video {
    position: absolute;
    bottom: -170px;
    left: 10px;
    &::before {
      content: "IG_1";
      background: rgba($yellow, 0.8);
      border-radius: 3px;
      font-size: 14px;
      padding: 4px 4px;
      line-height: 10px;
      color: white;
      position: absolute;
      left: 10px;
      top: -20px;
    }
    & video {
      border: 1px solid $yellow;
    }
    @media (orientation: portrait) {
      top: 125%;
      left: 0px;
      @media (max-height: 1500px) and (min-width: 1000px) {
        top: 110%;
      }
    }
    @media (orientation: landscape) {
      top: 220px;
      left: calc(100% + 10px);
    }
    animation: screenAppears2 2s ease-out;
  }
  & .inGamePlayer2Video {
    position: absolute;
    bottom: -170px;
    right: 10px;
    &::before {
      content: "IG_2";
      background: rgba($blue, 0.8);
      border-radius: 3px;
      font-size: 14px;
      padding: 4px 4px;
      line-height: 10px;
      color: white;
      position: absolute;
      left: 10px;
      top: -20px;
    }
    & video {
      border: 1px solid $blue;
    }
    @media (orientation: portrait) {
      top: 125%;
      left: 55%;
      @media (max-height: 1500px) and (min-width: 1000px) {
        top: 110%;
      }
    }
    @media (orientation: landscape) {
      top: 320px;
      left: calc(100% + 10px);
    }
    animation: screenAppears2 2s ease-out;
  }
  // Buttons

  & button {
    z-index: 2;
    padding: 2px;
    background: #232323;
    width: 32px;
    height: 32px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .controlBar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0px;
    bottom: 20px;
    border-radius: 8px;
    background: #101010;
    width: calc(100% - 20px);
    height: 42px;
    margin: 0px 10px;
    opacity: 0;
    animation: fadeFromBottom 1s 1s ease-out forwards;
  }
  & .playButton {
    margin: 5px;
    // left: calc(50% + 8px);
    // bottom: 20px;
    border-radius: 8px;
    flex-grow: 1;
  }

  & .replay10sButton {
    margin: 5px;
    // left: calc(50% - 40px);
    // bottom: 20px;
    border-radius: 8px;
  }

  & .resyncButton {
    margin: 5px 5px 5px 50px;
    justify-self: flex-end;
    align-self: flex-end;
    border-radius: 8px;
    color: $dataBlue;
  }

  & .stallButton {
  }

  & .exitButton {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 8px;
    opacity: 0;
    //   border: 1px solid white;

    @media (orientation: portrait) {
    }
    animation: fadeFromTop 0.5s 2s ease-out forwards;
  }

  & .exitButton2 {
    position: absolute;
    left: 10px;
    bottom: 10px;
    border-radius: 8px;
    opacity: 0;
    //   border: 1px solid white;

    @media (orientation: portrait) {
    }
    animation: fadeFromBottom 0.5s 2s ease-out forwards;
  }

  & .loadingContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// @keyframes screenAppears {
//   from {
//     opacity: 0;
//     transform: rotateX(-90deg);
//   }
//   to {
//     opacity: 0.5;
//     transform: rotateX(0deg);
//   }
// }

@keyframes screenAppears1 {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  50% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 0.5;
    transform: translateX(0px);
  }
}

@keyframes screenAppears2 {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  50% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 0.5;
    transform: translateX(0px);
  }
}

@keyframes bgAppears {
  from {
    transform: scale(0.8);
    background-color: #17171700;
    border-color: #05050500;
  }
  to {
    transform: scale(1);
    background-color: #171717ff;
    border-color: #050505ff;
  }
}
