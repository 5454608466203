.backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2000;
  overflow: hidden;
  animation: fade 1s ease-in-out;
}

.modal {
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0.1));
  z-index: 2001;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20px;
  overflow-y: scroll;
  animation: modalAppear 0.5s ease-in-out forwards;
}

.closeIcon {
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  margin-bottom: 50px;
  cursor: pointer;
  & .closeBar {
    width: 30px;
    height: 3px;
    background: white;
    margin: 3px 0px;
  }
  & .closeBar:first-child {
    transform: rotate(45deg) translateY(7px);
  }
  & .closeBar:nth-child(2) {
    transform: rotate(-45deg) translateY(-7px);
  }
}

@keyframes modalAppear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(100%);
  }
}
