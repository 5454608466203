@import "../../App.scss";
@import "../../variables.scss";

.chapter {
  padding: 20px;
  font-size: 20px;
  font-weight: 700;
  color: #171717;
  border-radius: 5px;
  position: relative;
  animation: fadeFromBottom 2s ease-in-out;
  // box-shadow: 0px 0px 5px 5px #ffffff33;
}

.chapter1 {
  // background : #00deffee;
  background: #79d4ff;

  // background-image: url("../../assets/illu_chap1.svg"),
  //   linear-gradient(45deg, rgba(17, 17, 17, 0.1), rgba(17, 17, 17, 0.8));
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: luminosity;
  margin-top: 50px;
  padding-top: 80px;
  color: white;
  display: flex;
  flex-direction: column;
  // padding-left: 50px;
  // padding-right: 50px;
  width: 100%;

  & > .chapterTitle {
    position: absolute;
    top: -80px;
  }
}

.chapterTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: fit-content;
  padding: 10px;
  font-weight: 900;
}

.chapterIcon {
  border-radius: 50%;
  border: 5px solid rgb(195, 135, 5);
  animation: turn 10s linear infinite;
  box-shadow: 0px 0px 5px 5px white;
}

.chapterText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  color: white;
}

.chapterIndex {
  font-size: 30px;
  background: linear-gradient(45deg, #cc0000, #ffbc00);
  // font-weight: 900;
  @extend .polygonBg1;
  font-family: "Osaka";
}

.chapterLabel {
  background: linear-gradient(45deg, #cc0000, #ffbc00);
  padding: 2px 20px;
  border-radius: 5px 5px 5px 0px;
  margin-left: 5px;
  width: fit-content;
  // @extend .polygonBg1;
  > div {
    // transform-origin: -30px -30px;
    // transform: rotateZ(-3deg) translateY(-5px) translateX(0px);
  }
}

.episode {
  background-color: #171717;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 5px;
  padding: 10px;
  min-width: 300px;
  border-radius: 5px;
  // @media (orientation: portrait) {
  //   flex-direction: column;
  // }
}

.episodeText {
  display: flex;
  flex-direction: column;
}

.episodeSubtitle {
  border-radius: 5px;
  color: white;
  padding: 5px;
  width: fit-content;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 2px;
  // color: #cccccc;
  // height: 72px;
  // font-size: 30px;
}
.episodeTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
.episodeTitle,
.episodePalier {
  display: inline-flex;
  font-size: 15px;
  border-radius: 5px;
  color: white;
  padding: 5px;
  font-weight: 700;
  color: #cccccc;
  // height: 72px;
  // font-size: 30px;
}

.episodePalier {
}

.episodeDescription {
  padding: 10px;
  font-size: 15px;
  color: white;

  // &:not(.currentEpisodeDescription) {
  //   border-left: 3px solid rgb(17, 17, 17);
  // }
  // &.currentEpisodeDescription {
  //   border-left: 3px solid #c00000;
  // }
  &.currentEpisodeDescription + .episode {
    opacity: 0.5;
  }

  &.currentEpisodeDescription + .episode + .episodeDescription {
    opacity: 0.5;
  }
}

.episodeIllu {
  transform: rotate3d(0.9, 0.2, 0.1, -25deg);
}

.chapterDescription {
  font-size: 15px;
  // animation: fadeFromLeft 1s ease-in-out;
  background-color: #eeeeee;
  color: #171717;
  border-radius: 5px;
  padding: 10px;
  // animation: fadeFromLeft 1s ease-in-out;
  position: relative;
}

.triUp {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #eeeeee;
  border-left: 15px solid transparent;

  position: absolute;
  top: -30px;
  left: 15px;
}

.arc {
  font-size: 30px;
  text-align: center;
  opacity: 0.5;
}

.timeline {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  overflow-x: scroll;
  transition: all 0.5s ease-in-out;
  position: relative;
  scroll-behavior: smooth;

  &.timelineNotInView {
    opacity: 0;
    transform-origin: 0px center;
    transform: translateX(-100px) scale(0.6) rotateY(45deg);
  }

  & .chevronContainer {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 15px);
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    transition: 1s all ease-in-out;
    opacity: 1;
    cursor: pointer;

    &.hidden {
      opacity: 0;
    }

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    & > .chevron.left {
      transform: translateY(5px) translateX(-3px);
    }

    & > .chevron.right {
      transform: translateY(5px) translateX(-3px);
    }

    &:not(.hidden) {
      animation: softBlink 3s ease-in-out infinite;
    }
  }

  scrollbar-color: #111111;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffbc00;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #111111;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #ffa600;
  }
}

.cursor {
  animation: blink 0.5s linear infinite;
}

.seeEpisodeButton {
  font-size: 14px;
  font-weight: 700;
  margin: 8px 0px;
  background: white;
  color: #050505;
}

// .currentEpisode {
//   border: 2px solid #c00000;
//   color: #c00000;
// }

// ** BOOK PAGES ** //

.bookContainer {
  margin-bottom: 20px;
  display: flex;
  background-color: #79d4ff;
  @media (orientation: landscape) {
    width: calc(100vw - 200px);
    padding: 20px;
    text-align: left;
    flex-direction: row;
    overflow-x: scroll;
  }

  @media (orientation: portrait) {
    width: 100%;
    padding: 50px 80px;
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    padding-bottom: 50px;
    overflow-x: scroll;
  }
}

.bookpage {
  display: flex;
  text-align: left;
  @media (orientation: portrait) {
    height: 80vh;
    min-height: 80vh;
    width: 80vw;
    min-width: 80vw;
    // margin-bottom: 20px;
  }
  @media (orientation: landscape) {
    margin-right: 20px;
    min-width: 400px;
    width: 400px;
    height: 710px;
  }
  color: white;
  border: 2px solid white;
  // background: #000000cc;
  background: linear-gradient(120deg, #008a9d, #008a9d 40%, #008a9d);
  padding: 20px 40px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  .pageHeader {
    width: 100%;
    display: flex;
    align-self: flex-start;
    justify-content: center;
    text-align: center;
  }
  .pageFooter {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  .footNote {
    display: flex;
    font-size: 11px;
    margin-top: auto;
    position: absolute;
    bottom: 20px;
  }
}

.pageContent {
  height: 50%;
  min-height: 50%;
  align-content: center;
}

.pageNoteNumber {
  // display: contents;
  // position: relative;
  font-size: 11px;
  // font-variant-position: super;
  // transform: translateY(-10px);
}

.currentEpisode {
  box-sizing: border-box;
  border: 8px dashed $yellow;
  .episodePalier {
    color: $yellow;
  }
}
// ** END BOOK PAGES ** /
