.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  animation: fadeFromTop 1s ease-in-out;
  // min-height: 100vh;
  // background-color: rgb(92, 0, 0);
  // animation: darken ease-in 1s 4s forwards;
}

.message {
  color: white;
  padding: 20px;
  margin: 50px;
  padding: 20px;
  margin-top: 200px;
  font-size: 40px;
  text-align: left;
  animation: titlePop 5s ease-in-out forwards;
  border-radius: 5px;
  z-index: 2;
}

.cloud {
  z-index: 2;
  position: absolute;
  right: 0px;
  top: -50px;
  animation: cloud 20s linear forwards;
}

.topbar {
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 0;
  // animation: logoAppears 7s 0s ease-in-out;
  justify-content: space-between;
  align-items: center;
  left: 0px;
  position: fixed;
  top: 0px;
  padding: 10px;
  width: calc(100% - 20px);
  // background: linear-gradient(90deg, #ff3200 20%, white 30%, #00deff 40%);
  background: linear-gradient(90deg, #ffffff 21%, white 21%, #79d4ff 21%);
  @media (orientation: portrait) {
    // background: linear-gradient(90deg, #111111 20%, white 20%, #00deff 20%);
    // background: linear-gradient(90deg, #ff3200 40%, white 50%, #00deff 60%);
  }
  // background-color: rgba(17, 17, 17, 0.9);
}
.logoImage {
  // animation: turn 12s 1s linear infinite;
}

.imageContainer {
  opacity: 0;
  height: 500px;
  width: 100%;
  overflow: hidden;
  position: relative;
  animation: fade 4s ease-in-out 0s forwards;
}

.textOnImage {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  font-size: 30px;
}

.title {
  opacity: 0;
  font-weight: bold;
  animation: fadeFromTop ease-in-out 1s 0s forwards;
  font-size: 40px;
  color: #333333;
  font-family: "Osaka";
}

.subtitle {
  opacity: 0;
  animation: fade ease-in-out 5s 0.5s forwards;
  color: #000000;
  font-size: 30px;
  position: relative;
}

.subsubtitle {
  color: #444444;
  position: absolute;
  @media (orientation: landscape) {
    top: -10px;
    right: 0px;
    font-size: 18px;
  }
  @media (orientation: portrait) {
    top: -10px;
    right: 0px;
    font-size: 10px;
  }
}

.description {
  font-size: 20px;
  color: #111111;
  // background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  margin-top: 20px;
  animation: fadeFromTop ease-in-out 1s 0s forwards;
}

.caption {
  width: fit-content;
  opacity: 0;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 18px;
  padding: 10px 10px;
  border-radius: 5px;
  color: #111111;
  background-color: rgba(150, 0, 0, 0.9);
  cursor: pointer;
  font-weight: bold;
  box-shadow: white 0px 0px 10px 2px;
  // animation: fadeFromBottom ease-in-out 1s 2s forwards;
  animation: softBounce ease-in-out 5s infinite;
}

.textBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 100px 20px 20px 20px;
  padding: 20px;
  align-items: center;
  // background: rgba(17, 17, 17, 0.9);
  background: #79d4ff;
  border: 5px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  border-image-width: 15px 15px 0px 0px;
  border-image-outset: 0px 15px;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 10s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%232d3561' /%3E%3Cstop offset='25%25' stop-color='%23c05c7e' /%3E%3Cstop offset='50%25' stop-color='%23f3826f' /%3E%3Cstop offset='100%25' stop-color='%23ffb961' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E")
    1;
}

@media (orientation: landscape) {
  .App-header {
    flex-direction: row;
  }
  .title {
    font-size: 70px;
  }
  .subtitle {
    font-size: 50px;
  }
  .textBlock {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    // border-left: 10px solid white;
    max-width: 50%;
  }

  .imageContainer {
    width: auto;
    height: auto;
  }
  .background {
    height: 100vh;
    width: auto;
  }
}

/* Animations */

@keyframes titlePop {
  0% {
    opacity: 0;
    transform: scale(0.5);
    clip-path: polygon(0 0, 100% 0, 10% 10%, 0% 100%);
  }
  30% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    transform: scale(1);
  }
  90% {
    clip-path: polygon(10% 10%, 100% 0, 100% 100%, 0% 100%);
    opacity: 1;
  }
  100% {
    opacity: 0;
    clip-path: polygon(100% 100%, 100% 0, 100% 100%, 0% 100%);
  }
}

@keyframes darken {
  from {
    background-color: rgb(10, 10, 10);
  }
  to {
    background-color: rgba(10, 0, 37, 1);
  }
}

@keyframes logoAppears {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes cloud {
  0% {
    opacity: 1;
    right: 0%;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    right: 100%;
  }
}
